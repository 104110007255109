import { createSlice } from '@reduxjs/toolkit';
import { initialModalState, ModalName } from './constant/modal.constant';
import { ModalInterface } from './interface/modal.interface';
import { RootState } from '@redux/rootReducer';

export const modalSlice = createSlice({
  name: 'modal/slice',
  initialState: {
    modalState: initialModalState as Record<ModalName, ModalInterface>,
  },
  reducers: {
    updateModalState: (state, action) => {
      state.modalState = action.payload;
    },
  },
});

export const selectModalState = (state: RootState, name: ModalName) => state.modal?.modalState[name];
const { actions, reducer } = modalSlice;
export const { updateModalState } = actions;
export default reducer;
