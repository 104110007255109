import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RequestState } from '@services/settings/type/type';
import { RootState } from '@redux/rootReducer';
import {
  dropdownDBSInterface,
  IncomingPaymentBankInterface,
  IncomingPaymentFilterInterface,
  IncomingPaymentInterface,
  transactionMatchingPayload,
  virtualAccountDBSInterface,
} from './interface/incoming-payment.interface';
import { virtualAccountDBSMapper } from '@redux/incoming-payment/helpers/incoming-payment.mapper';
import {
  getDBSVirtualAccountService,
  getIncomingPaymentsBankService,
  getIncomingPaymentsService,
  getInvoicesOutstanding,
  getInvoicesPaid,
  getInvoicesPartial,
  matchingTransactionPaymentService,
} from './incoming-payment.service';

export const fetchIncomingPayments = createAsyncThunk<any, IncomingPaymentInterface[]>(
  'incoming-payment/get-incoming-payments',
  async (payload, { rejectWithValue }) => {
    const response = await getIncomingPaymentsService(payload);
    if (response?.status_code !== 200) {
      rejectWithValue(response);
    }
    return response.data || null;
  },
);

export const fetchIncomingPaymentBanks = createAsyncThunk<IncomingPaymentBankInterface[]>(
  'incoming-payment/get-incoming-payment-banks',
  async (payload, { rejectWithValue }) => {
    const response = await getIncomingPaymentsBankService();
    if (response?.status_code !== 200) {
      rejectWithValue(response);
    }
    return response.data.banks || null;
  },
);

export const fetchVirtualAccountDBS = createAsyncThunk<virtualAccountDBSInterface[], dropdownDBSInterface[]>(
  'incoming-payment/virtual-account-dbs',
  async (payload, { rejectWithValue }) => {
    const response = await getDBSVirtualAccountService(payload);
    if (response?.staus_code !== 200) {
      rejectWithValue(response);
    }
    return response?.data ? virtualAccountDBSMapper(response?.data) : null;
  },
);

export const fetchInvoicesPaid = createAsyncThunk<string, any>('incoming-payment/invoices-paid', async (payload, { rejectWithValue }) => {
  const response = await getInvoicesPaid(payload);
  if (response?.staus_code !== 200) {
    rejectWithValue(response);
  }
  return response?.data || null;
});

export const fetchInvoicesOutstanding = createAsyncThunk<string, any>(
  'incoming-payment/invoices-outstanding',
  async (payload, { rejectWithValue }) => {
    const response = await getInvoicesOutstanding(payload);
    if (response?.staus_code !== 200) {
      rejectWithValue(response);
    }
    return response?.data || null;
  },
);

export const fetchInvoicesPartial = createAsyncThunk<string, any>(
  'incoming-payment/invoices-partial',
  async (payload, { rejectWithValue }) => {
    const response = await getInvoicesPartial(payload);
    if (response?.staus_code !== 200) {
      rejectWithValue(response);
    }
    return response?.data || null;
  },
);

export const matchingTransactionPayment = createAsyncThunk<any, transactionMatchingPayload>(
  'incoming-payment/matching-payment',
  async (payload, { rejectWithValue }) => {
    const response = await matchingTransactionPaymentService(payload);
    if (response?.staus_code !== 200) {
      rejectWithValue(response);
    }
    return response?.data;
  },
);

export const incomingPaymentSlice = createSlice({
  name: 'incoming-payment',
  initialState: {
    status: undefined as RequestState,
    data: undefined as IncomingPaymentInterface[],
    selectedData: undefined as IncomingPaymentInterface,
    invoices: {
      paid: undefined as any,
      paidLoading: false as boolean,
      outstanding: undefined as any,
      outstandingLoading: false as boolean,
      outstandingError: false as boolean,
      partial: undefined as any,
      partialLoading: false as boolean,
      partialError: false as boolean,
    },
    loading: false as boolean,
    filter: undefined as IncomingPaymentFilterInterface,
    dbs: {
      virtualAccount: undefined as virtualAccountDBSInterface[],
      virtualAccountLoading: false as boolean,
    },
    matchingPayment: {
      loading: false as boolean,
      success: false as boolean,
      error: false as boolean,
      data: undefined as any,
    },
    banks: undefined as IncomingPaymentBankInterface[],
  },
  reducers: {
    updateIncomingPaymentFilter: (state, action) => {
      state.filter = action.payload;
    },
    updateIncomingPaymentSelected: (state, action) => {
      state.selectedData = action.payload;
    },
    updateIncomingPaymentInvoices: (state, action) => {
      state.invoices = {
        ...action.payload,
        paidLoading: false,
      };
    },
    updateVirtualAccountDBS: (state, action) => {
      state.dbs = {
        ...state.dbs,
        virtualAccountLoading: false,
        virtualAccount: action.payload,
      };
    },
  },
  extraReducers: builder => {
    builder.addCase(fetchIncomingPayments.pending, state => {
      state.status = 'pending';
      state.loading = true;
    });
    builder.addCase(fetchIncomingPayments.fulfilled, (state, action) => {
      state.status = 'fulfilled';
      state.data = action.payload;
      state.loading = false;
    });
    builder.addCase(fetchIncomingPayments.rejected, state => {
      state.status = 'rejected';
      state.loading = false;
    });
    builder.addCase(fetchIncomingPaymentBanks.pending, state => {
      state.status = 'pending';
      state.loading = true;
    });
    builder.addCase(fetchIncomingPaymentBanks.fulfilled, (state, action) => {
      state.status = 'fulfilled';
      state.banks = action.payload;
      state.loading = false;
    });
    builder.addCase(fetchIncomingPaymentBanks.rejected, state => {
      state.status = 'rejected';
      state.loading = false;
    });
    builder.addCase(fetchVirtualAccountDBS.pending, state => {
      state.status = 'pending';
      state.dbs = {
        ...state.dbs,
        virtualAccountLoading: true,
      };
    });
    builder.addCase(fetchVirtualAccountDBS.fulfilled, (state, action) => {
      state.status = 'fulfilled';
      state.dbs = {
        virtualAccount: action.payload,
        virtualAccountLoading: false,
      };
    });
    builder.addCase(fetchVirtualAccountDBS.rejected, state => {
      state.status = 'rejected';
      state.dbs = {
        ...state.dbs,
        virtualAccount: undefined,
        virtualAccountLoading: true,
      };
    });
    builder.addCase(fetchInvoicesPaid.pending, state => {
      state.status = 'pending';
      state.invoices = {
        ...state.invoices,
        paidLoading: true,
      };
    });
    builder.addCase(fetchInvoicesPaid.fulfilled, (state, action) => {
      state.status = 'fulfilled';
      state.invoices = {
        ...state.invoices,
        paid: action.payload,
        paidLoading: false,
      };
    });
    builder.addCase(fetchInvoicesPaid.rejected, state => {
      state.status = 'rejected';
      state.invoices = {
        ...state.invoices,
        paid: undefined,
        paidLoading: true,
      };
    });
    builder.addCase(fetchInvoicesOutstanding.pending, state => {
      state.status = 'pending';
      state.invoices = {
        ...state.invoices,
        outstandingLoading: true,
        partialError: false,
      };
    });
    builder.addCase(fetchInvoicesOutstanding.fulfilled, (state, action) => {
      state.status = 'fulfilled';
      state.invoices = {
        ...state.invoices,
        outstanding: action.payload,
        outstandingLoading: false,
        partialError: false,
      };
    });
    builder.addCase(fetchInvoicesOutstanding.rejected, state => {
      state.status = 'rejected';
      state.invoices = {
        ...state.invoices,
        outstanding: undefined,
        outstandingLoading: false,
        partialError: true,
      };
    });
    builder.addCase(fetchInvoicesPartial.pending, state => {
      state.status = 'pending';
      state.invoices = {
        ...state.invoices,
        partialLoading: true,
        partialError: false,
      };
    });
    builder.addCase(fetchInvoicesPartial.fulfilled, (state, action) => {
      state.status = 'fulfilled';
      state.invoices = {
        ...state.invoices,
        partial: action.payload,
        partialLoading: false,
        partialError: false,
      };
    });
    builder.addCase(fetchInvoicesPartial.rejected, state => {
      state.status = 'rejected';
      state.invoices = {
        ...state.invoices,
        partial: undefined,
        partialLoading: false,
        partialError: true,
      };
    });
    builder.addCase(matchingTransactionPayment.pending, state => {
      state.status = 'pending';
      state.matchingPayment = {
        ...state.matchingPayment,
        loading: true,
        success: false,
        error: false,
      };
    });
    builder.addCase(matchingTransactionPayment.fulfilled, (state, action) => {
      state.status = 'fulfilled';
      state.matchingPayment = {
        ...state.matchingPayment,
        data: action.payload,
        loading: false,
        success: true,
        error: false,
      };
    });
    builder.addCase(matchingTransactionPayment.rejected, state => {
      state.status = 'rejected';
      state.matchingPayment = {
        ...state.matchingPayment,
        data: undefined,
        loading: false,
        success: false,
        error: true,
      };
    });
  },
});

export const selectIncomingPaymentData = (state: RootState) => state.incomingPayment?.data;
export const selectIncomingPaymentBanks = (state: RootState) => state.incomingPayment?.banks;
export const selectIncomingPaymentSelected = (state: RootState) => state.incomingPayment?.selectedData;
export const selectIncomingPaymentStatus = (state: RootState) => state.incomingPayment?.status;
export const selectIncomingPaymentLoading = (state: RootState) => state.incomingPayment?.loading;
export const selectIncomingPaymentFilter = (state: RootState) => state.incomingPayment?.filter;
export const selectDBSData = (state: RootState) => state.incomingPayment?.dbs;
export const selectInvoicesData = (state: RootState) => state.incomingPayment?.invoices;
export const selectMatchingPayment = (state: RootState) => state.incomingPayment?.matchingPayment;
export const { actions, reducer } = incomingPaymentSlice;
export const {
  updateIncomingPaymentFilter,
  updateIncomingPaymentSelected,
  updateIncomingPaymentInvoices,
  updateVirtualAccountDBS,
} = actions;
export default reducer;
