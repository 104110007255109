import axios from 'axios';
import { endpoint } from '@config/env';
import { getAccessToken, clearAll } from '@utils/storage';

export enum SERVER_CONFIG_ENUM {
  DBS = 'DBS',
}

const Client = axios.create({
  /**
   * Import the config from the src/config/env.ts file
   */
  baseURL: `${endpoint.baseUrl}${endpoint.version}`,
  headers: {
    'Content-Type': 'application/json',
  },
});

Client.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    if (error.response) {
      if (error.response.status === 401) {
        clearAll();
        window.location.href = '/auth/login';
      }
    }
    return Promise.reject(error?.response ? error?.response?.data : error);
  },
);

const getIntance = (url, params = {}, config = {}) =>
  Client.get(url, {
    params,
    ...config,
  });

const postIntance = (url, data, config) =>
  Client.post(url, data, {
    ...config,
  });

const putIntance = (url, data, config) =>
  Client.put(url, data, {
    ...config,
  });

const patchIntance = (url, data, config) =>
  Client.patch(url, data, {
    ...config,
  });

const deleteIntance = (url, config) =>
  Client.delete(url, {
    ...config,
  });

const deleteBulkIntance = (url, data: {}, config) => {
  return Client.delete(url, {
    data,
    ...config,
  });
};

const withAuth = async () => {
  const session = await getAccessToken();
  const token = session ? `Bearer ${session}` : null;

  const getConfig = config => {
    let customConfig = {
      ...config,
      headers: {
        ...config?.headers,
        Authorization: token,
      },
    };
    customConfig?.headers?.server && delete customConfig.headers.server;
    return {
      ...customConfig,
      headers: {
        ...customConfig?.headers,
        Authorization: token,
      },
    };
  };

  return {
    get: (url, params, config) => getIntance(url, params, getConfig(config)),
    post: (url, data, config) => postIntance(url, data, getConfig(config)),
    put: (url, data, config) => putIntance(url, data, getConfig(config)),
    patch: (url, data, config) => patchIntance(url, data, getConfig(config)),
    delete: (url, config) => deleteIntance(url, getConfig(config)),
    deleteBulk: (url, data, config) => deleteBulkIntance(url, data, getConfig(config)),
  };
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  get: getIntance,
  post: postIntance,
  put: putIntance,
  patch: patchIntance,
  delete: deleteIntance,
  deleteBulk: deleteBulkIntance,
  withAuth,
};
