import { ModalInterface } from '../interface/modal.interface';

export enum ModalName {
  CONFIRMATION = 'CONFIRMATION',
  FILTER_INCOMING_PAYMENT = 'FILTER_INCOMING_PAYMENT',
  MATCH_PAYMENT = 'MATCH_PAYMENT',
  INCOMING_PAYMENT_INVOICE = 'INCOMING_PAYMENT_INVOICE',
}

export const initialModalState = {
  [ModalName.CONFIRMATION]: {
    show: false,
    title: '',
    body: '',
  },
  [ModalName.FILTER_INCOMING_PAYMENT]: {
    show: false,
    title: '',
    body: '',
  },
  [ModalName.MATCH_PAYMENT]: {
    show: false,
    title: '',
    body: '',
  },
  [ModalName.INCOMING_PAYMENT_INVOICE]: {
    show: false,
    title: '',
    body: '',
  },
} as Record<ModalName, ModalInterface>;
