export const ApiEndpoint = {
  settings: {
    base: '/settings',
    region: {
      base: '/region',
      responsibleagent: '/responsible-agent',
    },
    locations: {
      base: '/locations',
      province: '/province',
      city: '/city',
      district: '/district',
      filter: {
        cityByName: '/city_by_name',
        districtByName: '/district_by_name',
      },
      locationRegionArea: '/location-region-area',
    },
    company: {
      companyRegionArea: {
        base: '/company-region-area',
        bulk: '/bulk-mapping',
      },
    },
    product: {
      dropdown: {
        packageCustomerCategory: '/leads/products/package-customer-category',
      },
    },
    customer: {
      category: '/leads/customers/categories',
    },
    leads: {
      transactions: {
        schema: '/leads/transaction/transaction-schema',
        accountType: '/account-type',
        invoiceByNumber: '/leads/invoice',
      },
    },
  },
  server: {
    dbs: {
      base: '/dbs-integration',
      virtualAccount: '/virtual-account',
      mutation: '/mutation',
      invoice: {
        base: '/invoice',
        paid: '/paid',
        outstanding: '/outstanding',
        partial: '/partial',
      },
      callback: 'leads/trx-payment-gateway/dbs-invoice-call-back',
      transaction: {
        base: '/transaction',
        matching: '/matching',
      },
    },
  },
};
