import { dropdownDBSInterface, virtualAccountDBSInterface } from '@services/incoming-payment/interface/incoming-payment.interface';
import { ACCOUNT_TYPE_ENUM } from '../constant/incoming-payment.constant';
import { OTHER_AGENTCY_ID } from '@utils/constant/global.constants';

export const dropdownDBSMapper: (data: any) => dropdownDBSInterface[] = (data: any) => {
  const agent: dropdownDBSInterface = {
    customer: {
      email: data?.email ?? '',
      name: data?.leads_name ?? '',
      reference_id: data?.customer_original_id ?? '',
      type: ACCOUNT_TYPE_ENUM.AGENT,
    },
  };

  const agency =
    data?.head_agency_id !== OTHER_AGENTCY_ID
      ? ({
          customer: {
            email: data?.agency_email ?? '',
            name: data?.agency ?? '',
            reference_id: data?.agency_original_id ?? '',
            type: ACCOUNT_TYPE_ENUM.AGENCY,
          },
        } as dropdownDBSInterface)
      : undefined;

  return [agent, agency].filter(Boolean) as dropdownDBSInterface[];
};

export const virtualAccountDBSMapper: (data: virtualAccountDBSInterface[]) => virtualAccountDBSInterface[] = (
  data: virtualAccountDBSInterface[],
) => {
  let result: virtualAccountDBSInterface[] = [];
  if (data) {
    result = data.map(item => {
      const label = `${item.customer.type === ACCOUNT_TYPE_ENUM.AGENCY ? 'Company Account' : 'Agent Account'} - ${item.customer.name} (${
        item.reference_id
      })`;
      return {
        ...item,
        customer: {
          ...item.customer,
        },
        label,
      };
    }) as virtualAccountDBSInterface[];
  }
  return result;
};
