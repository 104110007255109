export enum ACCOUNT_TYPE_ENUM {
  AGENT = 'AGENT',
  AGENCY = 'AGENCY',
}

export enum INCOMING_PAYMENT_TYPE_ENUM {
  UNMATCHED = 'unmatched',
  MATCHED = 'matched',
  PARTIAL_WITH_REFUND = 'partially_match_with_refund',
  PARTIAL_NO_REFUND = 'partially_match_no_refund',
}

export enum INCOMING_PAYMENT_TYPE_RESPONSE_ENUM {
  UNMATCHED = 'UNMATCHED',
  MATCHED = 'MATCHED',
  PARTIAL_WITH_REFUND = 'PARTIAL_WITH_REFUND',
  PARTIAL_NO_REFUND = 'PARTIAL_NO_REFUND',
}

export const convertIncomingPaymentType = (type: INCOMING_PAYMENT_TYPE_ENUM) => {
  switch (type) {
    case INCOMING_PAYMENT_TYPE_ENUM.MATCHED:
      return 'Matched';
    case INCOMING_PAYMENT_TYPE_ENUM.UNMATCHED:
      return 'Unmatched';
    case INCOMING_PAYMENT_TYPE_ENUM.PARTIAL_WITH_REFUND:
      return 'Partially Matched with Refund';
    case INCOMING_PAYMENT_TYPE_ENUM.PARTIAL_NO_REFUND:
      return 'Partially Matched with No Refund';
    default:
      return '';
  }
};

export const convertIncomingPaymentResponseType = (type: INCOMING_PAYMENT_TYPE_RESPONSE_ENUM) => {
  switch (type) {
    case INCOMING_PAYMENT_TYPE_RESPONSE_ENUM.MATCHED:
      return 'Matched';
    case INCOMING_PAYMENT_TYPE_RESPONSE_ENUM.UNMATCHED:
      return 'Unmatched';
    case INCOMING_PAYMENT_TYPE_RESPONSE_ENUM.PARTIAL_WITH_REFUND:
      return 'Partially Matched with Refund';
    case INCOMING_PAYMENT_TYPE_RESPONSE_ENUM.PARTIAL_NO_REFUND:
      return 'Partially Matched with No Refund';
    default:
      return '';
  }
};
export enum MATCH_PAYMENT_TABLE_ENUM {
  OUTSTANDING = 'OUTSTANDING',
  PARTIAL = 'PARTIAL',
  PAID = 'PAID',
}

export enum COLOR_AMOUNT_ENUM {
  YELLOW = 'yellow',
  GREEN = 'green',
  BLUE = 'blue',
  RED = 'red',
}

export const AMOUNT_AGGREGATION = 50000;
