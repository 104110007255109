import ApiClient from '@utils/api-client';
import { ApiEndpoint } from '@utils/constant/api-enpoint.constant';
import { createInvoicePayload, dropdownDBSInterface, transactionMatchingPayload } from './interface/incoming-payment.interface';

const { server } = ApiEndpoint;

const {
  dbs: { base: dbsPath, mutation, virtualAccount, transaction, invoice },
} = server;

const getIncomingPaymentsService = payload =>
  ApiClient.withAuth().then(api => api.get(`${dbsPath}${mutation}`, payload, {}).then(result => result?.data));

const getIncomingPaymentsBankService = () =>
  ApiClient.withAuth().then(api => api.get(`${dbsPath}${mutation}/sender-banks`, {}, {}).then(result => result?.data));

const getDBSVirtualAccountService = (payload: dropdownDBSInterface[]) =>
  ApiClient.withAuth().then(api => api.post(`${dbsPath}${virtualAccount}`, payload, {}).then(result => result?.data));

const matchingTransactionPaymentService = (payload: transactionMatchingPayload) =>
  ApiClient.withAuth().then(api =>
    api.post(`${dbsPath}${transaction.base}${transaction.matching}`, payload, {}).then(result => result?.data),
  );

const getInvoicesPaid = (payload: string) =>
  ApiClient.withAuth().then(api => api.get(`${dbsPath}${invoice.base}${invoice.paid}/${payload}`, {}, {}).then(result => result?.data));
const getInvoicesOutstanding = (payload: string) =>
  ApiClient.withAuth().then(api =>
    api.get(`${dbsPath}${invoice.base}${invoice.outstanding}/${payload}`, {}, {}).then(result => result?.data),
  );
const getInvoicesPartial = (payload: string) =>
  ApiClient.withAuth().then(api => api.get(`${dbsPath}${invoice.base}${invoice.partial}/${payload}`, {}, {}).then(result => result?.data));
const createInvoice = (payload: createInvoicePayload) =>
  ApiClient.withAuth().then(api => api.post(`${dbsPath}${invoice.base}`, payload, {}).then(result => result?.data));

export {
  getIncomingPaymentsService,
  getDBSVirtualAccountService,
  matchingTransactionPaymentService,
  getInvoicesPaid,
  getInvoicesOutstanding,
  getInvoicesPartial,
  createInvoice,
  getIncomingPaymentsBankService,
};
